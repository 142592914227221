@import '../../../assets/styles/base-hub.scss';
.register {
    margin: auto;
    height: 120vh;
    overflow: scroll;
    direction: rtl;
    max-width: 500px;
    background: #d9d9d9;
    &__container {
        margin: 50px 16px 0 15px;
    }
    &__seperator {
        border-top: 1px solid #d9d9d9;
        margin: 15px 0 0;
    }
    &__register-card {
        height: 600px;
        direction: rtl;
        display: flex;
        border-radius: 6px;
        margin: 22px 0 14px 0;
        flex-direction: column;
        background-color: #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    }
    &__header {
        &-title {
            font-size: 15px;
            line-height: 26px;
            font-weight: bold;
            font-style: normal;
            font-family: IRANYekan;
        }
        width: 100%;
        height: 52px;
        display: flex;
        padding: 0 14px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    &__body {
        height: 550px;
        display: flex;
        flex-direction: column;
        overflow: scroll;
    }
    &__section {
        display: flex;
        margin-right: 0;
        margin: 15px 15px 0;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        >div {
            display: inline-block;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 5px 0;
        }
        &--profile {
            align-items: flex-start;
        }
        span {
            display: flex;
            font-size: 14px;
            line-height: 22px;
            font-style: normal;
            font-weight: normal;
        }
        label {
            display: inline-block;
            font-size: 14px;
            min-width: 100px;
            margin-left: 5px;
            line-height: 22px;
            margin-right: 2px;
            font-style: normal;
            font-weight: normal;
            font-family: IRANYekan;
        }
        &-pricture {
            height: 100px;
            display: flex;
            flex-direction: row;
        }
        &-image {
            width: 100px;
            display: flex;
            font-size: 14px;
            margin-left: 10px;
            border-radius: 2px;
            align-items: center;
            background: #fafafa;
            flex-direction: column;
            box-sizing: border-box;
            justify-content: center;
            border: 1px dashed #d9d9d9;
            color: rgba(0, 0, 0, 0.45);
            img {
                width: 100px;
                height: 100px;
                object-fit: cover;
            }
        }
        input {
            margin: 0;
            font-size: 13px;
            min-width: 215px;
            color: rgba(0, 0, 0, 0.65);
        }
        button {
            margin: 10px auto 0;
        }
    }
}