.switch-building {
  margin: auto;
  height: 100vh;
  direction: rtl;
  max-width: 500px;
  overflow: scroll;
  background: #d9d9d9;
  font-family: iranyekan;

  &__container {
    margin: 0 15px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: IRANYekan;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 31px;
    color: #000000;
    margin-top: 30px;
  }

  &__user {
    height: 101px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    margin: 19px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 11px;

    &-info {
      display: flex;
      align-items: center;

      &__image {
        width: 80px;
        height: 80px;
        margin-left: 0;
        padding: 0;

        img {
          width: 80px;
          height: 80px;
          border: 1px solid #ffffff;
          box-sizing: border-box;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
          border-radius: 100px;
        }
      }

      &__desc {
        display: flex;
        flex-direction: column;
        margin-right: 12px;
        font-family: IRANYekan;
        font-style: normal;
        color: #000000;
        &--title {
          font-weight: 300;
          font-size: 13px;
          line-height: 22px;
        }
        &--name {
          font-weight: bold;
          font-size: 15px;
          line-height: 26px;
        }
        &--unit {
          font-size: 12px;
          font-weight: 300;
        }
      }
    }

    &-btn {
      font-family: IRANYekan;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      border: 1px solid #ffa70b;
      color: #ffa70b;
      box-sizing: border-box;
      border-radius: 4px;
      width: 90px;
    }
  }

  &__button {
    margin: 20px 0;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0.9;
    border: 1px dashed #39afca;
    box-sizing: border-box;
    border-radius: 6px;
    height: 68px;
    width: 100%;

    span {
      font-size: 13px;
      line-height: 30px;
      text-align: center;
      color: #39afca;
      font-weight: 500;
      font-family: IRANYekan;
      font-style: normal;
      font-weight: normal;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
