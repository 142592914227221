.header {
  display: flex;
  padding: 15px;
  direction: rtl;
  max-height: 110px;
  align-items: center;
  flex-direction: row;
  background: #ffffff;
  justify-content: space-between;

  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);

  > img {
    height: 50px;
  }

  span {
    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
      border-radius: 20px;
      box-sizing: border-box;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    }
  }
}
