.unit-list {
  font-family: IRANYekan;
  max-width: 500px;
  height: 100vh;
  overflow: scroll;
  margin: auto;
  background: #eeeeee;
  direction: rtl;

  > button {
    margin: auto;
  }

  &__box {
    margin: 16px;
    height: fit-content;
    //padding-bottom: 20px;
    background-color: white;
    border-radius: 6px 6px 6px 6px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  }

  &__header {
    position: relative;

    > img {
      width: 100%;
      height: 120px;
      opacity: 0.7;
      filter: brightness(0.4);
      border-radius: 6px 6px 0px 0px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    }

    > span {
      position: absolute;
      right: 22px;
      bottom: -10px;

      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 100px;
        box-sizing: border-box;
        border: 1px solid #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
        /* border-radius: 50%; */
      }
    }

    &-title {
      position: absolute;
      bottom: 15px;
      color: white;
      /* right: 0; */
      display: grid;
      right: 115px;

      span:first-child {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
        text-align: right;
      }

      span:last-child {
        font-weight: bold;
        font-size: 15px;
        line-height: 26px;
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 10px 0;
    padding-right: 15px;
    direction: rtl;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 31px;
    text-align: right;
    color: #000000;
  }

  .seperator {
    height: 0px;
    width: 100%;
    border: 1px solid #d9d9d9;
  }

  &__body {
    margin: 13px 16px 0;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    border-radius: 6px 6px 6px 6px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  }

  > .manager {
    margin: 16px 16px 0;
  }

  > .anouncemenets__header {
    margin: -6px 16px;
    text-align: right;
  }
}
