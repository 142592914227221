.manager {
  font-family: IRANYekan;
  max-width: 500px;
  margin: auto;

  > button {
    background: rgba(255, 255, 255, 0.5);
    opacity: 0.9;
    border: 1px dashed #39afca;
    box-sizing: border-box;
    border-radius: 6px;
    width: 91%;
    height: 70px;
    margin-left: 20px;

    span {
      font-size: 13px;
      line-height: 30px;
      text-align: center;
      color: #39afca;
      font-weight: 500;
      font-family: IRANYekan;
    }
  }
  &__box {
    margin: 16px;
    border-radius: 6px 6px 6px 6px;
    height: fit-content;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    background-color: white;
  }

  &__header {
    position: relative;

    > img {
      opacity: 0.7;
      filter: brightness(0.4);
      width: 100%;
      height: 120px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
      border-radius: 6px 6px 0px 0px;
    }

    > span {
      position: absolute;
      right: 22px;
      bottom: -10px;

      img {
        border: 1px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
        border-radius: 100px;
        /* border-radius: 50%; */
        width: 80px;
        height: 80px;
        object-fit: cover;
      }
    }

    &-title {
      position: absolute;
      bottom: 15px;
      color: white;
      /* right: 0; */
      display: grid;
      right: 115px;

      span:first-child {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
        text-align: right;
      }

      span:last-child {
        font-weight: bold;
        font-size: 15px;
        line-height: 26px;
      }
    }
  }

  &__price {
    text-align: center;
    margin-bottom: 20px;

    span:first-child {
      font-weight: normal;
      font-size: 13px;
      color: #999999;
      padding-left: 10px;
    }
  }

  &__statistics {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 10px;
    direction: rtl;

    &--disable {
      background-color: gainsboro;
    }

    > div {
      display: flex;
      min-height: 100px;
      align-items: center;
      flex-direction: column;
      min-width: calc(100% / 3);

      span {
        text-align: center;
        font-size: 13px;
        color: #999999;
      }

      div {
        width: 60px;
        height: 60px;
        display: flex;
        border-radius: 50%;
        align-items: center;
        box-sizing: border-box;
        justify-content: center;
        border: 1px solid #d9d9d9;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);

        svg {
          height: 30px;
          color: #ffa70b;
          width: 30px !important;
        }
      }
    }
  }

  hr {
    border-top: unset;
    margin-bottom: 15px;
    border-bottom: 1px solid #d9d9d9;
  }

  &__payment {
    display: flex;
    direction: rtl;
    margin: 30px 14px 15px;
    flex-direction: column;

    > div:first-child {
      width: 100%;
      display: flex;
      color: #000000;
      justify-content: space-between;

      span:first-child {
        font-size: 13px;
        line-height: 22px;
        font-weight: normal;
      }

      span:last-child {
        font-weight: bold;
        font-size: 13px;
        line-height: 22px;
      }
    }

    > div:last-child {
      height: 30px;
      background: #d9d9d9;
      width: 100%;
      border-radius: 4px;
      margin-top: 10px;
      position: relative;

      > div {
        right: 0;
        width: 60%;
        height: 100%;
        display: flex;
        position: absolute;
        text-align: center;
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        background: linear-gradient(90deg, #ffbe16 0%, #ffa70b 100%);

        span {
          font-weight: 300;
          font-size: 13px;
          color: #ffffff;
        }
      }
    }
  }

  &__costs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;

    > span {
      color: #9b9b9b;
      margin-bottom: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
    }

    &-box {
      height: 35px;
      display: flex;

      div:first-child {
        border-radius: 5px 0px 0px 5px;
      }

      div:last-child {
        border-radius: 0px 5px 5px 0px;
      }

      > div {
        height: 100%;
        color: white;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
