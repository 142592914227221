@import '../../../assets/styles/base-hub.scss';
.home {
  background-image: url('../../../assets//img/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  direction: rtl;

  @include makeCenter(center, space-between, column);

  &__header {
    @include makeCenter(center, center, column);
    margin-top: 60px;

    span {
      text-align: center;
      margin: 10px;
      color: $white;
      border: 1px solid $black;
      border-radius: 6px;
      font-weight: bold;
      font-size: 19px;
      padding: 0px 10px;
      line-height: 30px;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  &__footer {
    margin-bottom: 80px;
    width: 100%;
    @include makeCenter(center, center, column);
  }

  button:first-child {
    margin-bottom: 15px;
  }

  &__copyright{
    span {
      margin: 58px 0 0;
      width: 295px;
      height: 53px;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 23px;
      color: white;
      @include makeCenter(center, center, column);

    }
  }


}
