@import '../../assets/styles/base-hub.scss';

input {
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 4px;
  height: 35px;
  margin: 6px 12px;
  text-align: right;
  padding-right: 12px;
  font-family: iranyekan;

  &::placeholder {
    color: rgba(0, 0, 0, 0.65);
    opacity: 0.4;
  }
}

textarea {
  width: 70%;
  margin: 6px 0;
  padding: 10px;
  font-size: 13px;
  border-radius: 4px;
  font-family: iranyekan;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
}

form {
  @include makeCenter(center, space-between, row);
}

.number-specific {
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    margin-left: 0.55rem;
    font-weight: normal;
    font-size: 13px;
  }
}
.input-nmuber {
  width: fit-content;
  height: fit-content;
  position: relative;

  input[type='number'] {
    width: 90px;
    height: 40px;
    left: 175px;
    top: 210px;
    background: transparent;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 2px;
    margin: unset;
    text-align: center;
    padding-right: 20px;
    font-family: iranyekan;
    outline: unset;
  }

  > div {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    border-left: 1px solid #d9d9d9;

    hr {
      width: 20px;
      opacity: 0.4;
    }

    small:first-child {
      position: absolute;
      top: 0;
      color: #8c8c8c;
      width: 10px;
      text-align: center;
      height: 50%;
      width: 100%;
    }
    small:last-child {
      position: absolute;
      bottom: 0;
      font-size: 11px;
      color: #8c8c8c;
      width: 100%;
      text-align: center;
      height: 50%;
    }
  }
}
