@import '../../assets/styles/base-hub.scss';

.addtoHomeClose {
  width: 100%;
  @include makeCenter(center, flex-end, row);
  padding: 8px 8px;
  &__button {
    background-color: #fff;
    border: none;
  }
}

.containerAddToHome {
  padding: 8px 32px 32px;
  @include makeCenter(center, flex-start, column);
  &__logo {
    border-radius: 6px;
    width: 180px;
    margin-bottom: 28px;
  }
  &__title {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 28px;
  }
  &__description {
    text-align: center;
    line-height: 18px;
    font-size: 12px;
    margin-bottom: 28px;
  }

  &__row {
    width: 100%;
    @include makeCenter(center, space-between, row);
    margin-bottom: 18px;

    > span {
      font-size: 12px;
      > span {
        font-weight: bold;
        color: #ffa70b;
        margin: 0 4px;
      }
    }

    > img {
      width: 20px;
    }
  }

  > button {
    font-family: iranyekan;
    margin-top: 10px;
    width: 100%;
    height: 48px;
    background: #ffa70b;
    color: white;
    border-radius: 10px;
    border: none;
  }
}
