@import '../../../assets/styles/base-hub.scss';
.unit-card {
  font-family: IRANYekan;
  direction: rtl;
  display: flex;
  flex-direction: column;

  &__tabs {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;

    button {
      height: 35px;
      width: 100%;
      border: 1px solid #d9d9d9;
      border-bottom: none;
      box-sizing: border-box;
      border-radius: 5px 5px 0px 0px;
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: IRANYekan;
      }

      &:active {
        color: darken($white, 15%);
      }
    }
  }

  &__active {
    background: #ffffff;
    color: #646464;
  }
  .unit-card__container {
    padding: 11px 14px 14px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .unit-number {
      display: flex;
      align-items: center;
      justify-content: center;

      &__icon {
        color: #ffa70b;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 17px;
        margin-left: 5px;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 26px;
        text-align: center;
        color: #000000;
      }
    }
    .status {
      display: flex;
      align-items: center;

      &__icon {
        font-size: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 6px;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 22px;
        text-align: right;
      }

      &--approved {
        color: #26de81;
      }
      &--pending {
        color: #fed330;
      }
      &--insert {
        color: #d9d9d9;
      }
    }
    .expand-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #d9d9d9;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 22px;
      cursor: pointer;
    }
  }
  &__body {
    margin-top: 10px;

    &-line {
      border: 1px solid #d9d9d9;
      width: 100%;
    }
    &-info {
      display: flex;
      justify-content: space-between;
      margin-top: 9px;
      font-family: IRANYekan;
      font-style: normal;
      font-weight: normal;

      .detail {
        display: flex;
        &__subject {
          display: flex;
          flex-direction: column;
          margin-left: 11px;
          font-size: 13px;
          line-height: 30px;
          text-align: right;
          color: #b4b4b4;
        }
        &__data {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          line-height: 30px;
          text-align: right;
          color: #646464;
        }
      }

      .members {
        padding-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        &__icon {
          font-size: 17px;
          margin-left: 8px;
          color: #000000;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        span {
          font-size: 13px;
          line-height: 22px;
          text-align: right;
          color: #646464;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &--resident {
      background: #ffffff;
      color: #646464;
      margin-left: 4px;
    }
    &--host {
      background: #f5f5f5;
      color: #b0b0b0;
    }

    &-btns {
      margin-top: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &--expanded {
      display: block;
      transition: height 0.3s ease-out;
    }
    &--collapsed {
      display: none;
      transition: height 0.3s ease-out;
    }
  }
  &__seperator {
    border: 1px solid #d9d9d9;
    height: 0;
    width: 100%;
  }
}
