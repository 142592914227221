// your ui base template colors
$white: #fff;
$black: #000;

/* colors ------------------*/
$primary_color: #39afca;
$secondary_color: #ffa70b;

$gray_color: #656565;

// Grid columns
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width: 40px !default;

$base-border-radius: 4px;
$card-border-radius: 13px;
