@import '../../../assets/styles/base-hub.scss';
.report {
    >* {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    }
    margin: auto;
    height: 100vh;
    overflow: scroll;
    direction: rtl;
    max-width: 500px;
    background: #d9d9d9;
    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: IRANYekan;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        color: #000000;
        margin-top: 30px;
    }
    &__seperator {
        border-top: 1px solid #d9d9d9;
        margin: 15px 0 0;
    }
    &__container {
        margin: 5px 10px 0 10px;
    }
    &__report-card {
        height: 448px;
        direction: rtl;
        display: flex;
        border-radius: 6px;
        margin: 22px 0 14px 0;
        flex-direction: column;
        background-color: #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    }
    &__header {
        width: 100%;
        height: 60px;
        margin-top: -10px;
        margin-bottom: 16px;
        background-color: white;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        padding: 0 16px;
        @include makeCenter(center, space-between, row);
        >span {
            font-family: IRANYekan;
            font-weight: bold;
            font-size: 18px;
            line-height: 31px;
        }
    }
    &__filter {
        @include makeCenter(center, start, row);
        border-bottom: 1px solid #d9d9d9;
        margin-top: 7px;
        padding: 4 12px;
        >* {
            margin: 4px;
        }
    }
    &__body {
        height: calc(115vh - 300px);
        background-color: white;
        border-radius: 6px;
        overflow: auto;
    }
    &__units {
        text-align: center;
        font-family: IRANYekan;
        border-collapse: collapse;
        border: 0px solid #ddd;
        width: 100%;
        justify-content: center;
        td,
        th {
            border: 1px solid #ddd;
            padding: 8px;
        }
        tr:hover {
            background-color: #ddd;
        }
        th {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: center;
            background-color: #60a6f7f1;
            color: white;
        }
        tbody {
            white-space: nowrap
        }
    }
    &__section {
        display: flex;
        margin-right: 0;
        margin: 15px 15px 0;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}