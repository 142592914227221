@import './assets//styles/base-hub.scss';

body {
  font-family: iranyekan;
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #000;
  outline: 1px solid #000;
}

body {
  font-size: 14px;
  line-height: 1.7;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h1 {
  font-size: 1.5em;
}

a,
a:link {
  text-decoration: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

a {
  color: #000;
}

.btn {
  display: inline-flex;
  padding: 10px 30px;
  font-size: 1.2em;
  @include border-radius(4px);
  color: #fff;
  border-color: $primary_color;
  background-color: $primary_color;
  @include transition(all, 0.3s, ease);
  &:hover {
    color: #fff;
    background-color: darken($color: $primary_color, $amount: 10);
  }
}

h2.hTitle {
  text-align: center;
  font-weight: bold;
}

h3.hTitle {
  font-size: 15px;
  font-weight: bold;
  position: relative;
  margin: 0;
  padding: 6px 0;
  margin-bottom: 6px;
  &:after {
    content: '';
    display: block;
    width: 40px;
    height: 2px;
    margin-top: 5px;
    background: $primary_color;
  }
}

.titleRow {
  margin-bottom: 30px;
  padding-right: 20px;
  padding-left: 20px;
  h2 {
    font-size: 2em;
    margin: 0;
    color: $primary_color;
  }
  h4 {
    font-weight: 300;
    color: $gray_color;
    font-size: 1.3em;
  }
}

.center {
  text-align: center;
  justify-content: center;
}
