@import '../../assets/styles/base-hub.scss';

.IB-button {
  border: 0;
  padding: 0;
  outline: 0;
  min-width: 175px;
  font-weight: normal;
  background: $secondary_color;
  @include easeTransition(0.1s);
  border-radius: $base-border-radius;
  @include makeCenter(center, center, row);

  &:active {
    background: darken($secondary_color, 10%);
  }

  &__label {
    margin: 0px;
    position: relative;
    top: -0.05em;
    color: $white;
    font-size: 13px;
    font-weight: normal;
    @include easeTransition(0.2s);
    font-family: iranyekan;
  }

  &__icon {
    width: 18px;
    min-height: 18px;
    margin-left: 0.5rem;
    position: relative;
    top: -1px;
    background-color: lighten($primary-color, 15%);
    @include easeTransition(0.2s);

    &--left {
      margin-left: 0;
      margin-right: 0.5rem;
    }
  }
  &__listStyleIcon {
    background-color: lighten($secondary_color, 15%) !important;
    min-width: 8px;
    min-height: 8px;
    margin-right: 24px;
    @include easeTransition(0.2s);
  }

  &--bordered {
    border-radius: 4px;
    @include easeTransition(0.2s);
    background-color: transparent;
    /* border-radius: 2.5rem; */
    border: 1px solid #999999;

    > p {
      top: 0px;
      font-size: 0.75rem;
      color: #999999;
    }

    &:active {
      background: white;

      > div {
        background-color: darken($white, 8%);
      }
      > p {
        color: darken($white, 15%);
      }
    }
  }

  &--disabled {
    background-color: gray;
    opacity: 0.5;
    @include easeTransition(0.2s);
  }

  &--success {
    background-color: $secondary_color;
    @include easeTransition(0.2s);

    &:active {
      background-color: darken($secondary_color, 5%);
    }

    > p {
      font-size: 1.05rem;
    }
  }

  &--solid {
    background-color: $white;
    border-radius: 2.5rem;
    box-shadow: 0 0.4em 0.6em rgba($black, 0.05);

    &:active {
      background-color: darken($white, 10%);
    }

    > p {
      font-size: 0.7rem;
      position: relative;
      top: 0.02em;
      color: lighten($primary-color, 15%);
    }

    &-active {
      background-color: $secondary_color;

      // > p { color: darken($secondary_color, 15%) }
      > p {
        color: $white;
      }

      &:active {
        background-color: darken($white, 3%);
      }
    }
  }

  &--elevated {
    background-color: $white;
    border-radius: 2.5rem;
    border: 1px solid rgba($black, 0.04);
    box-shadow: 0 0.4em 0.5em rgba($black, 0.07);

    &:active {
      background-color: darken($white, 10%);
    }

    > p {
      font-size: 0.75rem;
      position: relative;
      top: 0.02em;
      color: lighten($primary-color, 15%);
    }

    &-active {
      background-color: $secondary_color;

      // > p { color: darken($secondary_color, 15%) }
      > p {
        color: $white;
      }

      &:active {
        background-color: darken($white, 3%);
      }
    }
  }

  &--accept {
    background-color: darken($secondary_color, 7%);
    @include easeTransition(0.2s);

    &:active {
      background-color: darken($secondary_color, 20%);
    }

    > p {
      font-size: 1.05rem;
    }
  }
  &--reject {
    background-color: #d9d9d9;
    border-radius: 0.33rem;
    border: 1px solid #fc5c65;
    @include easeTransition(0.2s);

    &:active {
      background-color: darken($white, 5%);
    }

    > p {
      font-size: 0.77rem;
      color: #fc5c65;
    }
  }
  &--accept-white-bg {
    background-color: $white;
    border-radius: 0.33rem;
    border: 1px solid $secondary_color;
    @include easeTransition(0.2s);

    &:active {
      background-color: darken($white, 5%);
    }

    > p {
      font-size: 0.77rem;
      color: $secondary_color;
    }
  }

  &--primary-white-bg {
    background-color: $white;
    border: 1px solid $secondary_color;
    @include easeTransition(0.2s);

    &:active {
      background-color: darken($white, 5%);
    }

    > p {
      font-size: 0.77rem;
      color: $secondary_color;
    }
  }

  &--reject-white-bg {
    background-color: $white;
    @include easeTransition(0.2s);
    border: 1px solid darken($primary-color, 20%);

    &:active {
      background-color: darken($white, 25%);
    }

    > p {
      font-size: 1.05rem;
      color: $secondary_color;
    }
  }
}
