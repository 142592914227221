@import '../../../assets/styles/base-hub.scss';
.signup {
    background-image: url('../../../assets//img/bg2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 30px;
    overflow: scroll;
    direction: rtl;
    @include makeCenter(center,
    space-between,
    column);
    &-box {
        height: fit-content;
        margin: auto;
        width: 100%;
        @include makeCenter(center,
        center,
        column);
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 0px 0px 6px 6px;
        max-width: 500px;
        >h2 {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 30px;
            padding: 20px 0;
            >a {
                font-weight: bold;
                font-size: 14px;
                line-height: 30px;
                color: $primary_color;
                padding-right: 5px;
                cursor: pointer;
            }
        }
        h3 {
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            margin-top: 15px;
        }
    }
    &__selection {
        @include makeCenter(center,
        center,
        row);
        margin-bottom: 10px;
    }
    &__main {
        width: 100%;
        padding: 0 30px;
        h2 {
            margin-top: 20px;
            text-align: center;
            font-weight: bold;
            font-size: 20px;
            line-height: 35px;
        }
        button {
            p {
                font-weight: normal;
                font-size: 13px;
            }
        }
        input {
            width: 100%;
            margin: 6px 0;
        }
        label {
            display: block;
        }
    }
    &__header {
        height: 150px;
        background: $secondary_color;
        width: 100%;
        text-align: center;
        border-radius: 6px 6px 0px 0px;
        img {
            height: 100%;
            width: 50%;
        }
    }
}