@import '../../../assets/styles/base-hub.scss';

.costs {
  > * {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  }
  &__header {
    width: 100%;
    height: 60px;
    margin-top: -10px;
    margin-bottom: 16px;
    background-color: white;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 0 16px;
    @include makeCenter(center, space-between, row);
    > span {
      font-family: IRANYekan;
      font-weight: bold;
      font-size: 18px;
      line-height: 31px;
    }
    > a {
      border-radius: 4px;
      padding: 2px 14px;
      font-family: IRANYekan;
      font-size: 13px;
      line-height: 30px;
      background-color: #ffa70b;
      color: white;
    }
  }
  &__body {
    height: calc(100vh - 296px);
    background-color: white;
    border-radius: 6px;
    overflow: auto;
  }
  &__filter {
    @include makeCenter(center, start, row);
    border-bottom: 1px solid #d9d9d9;
    margin-top: 12px;
    padding: 0 12px;
    > * {
      margin: 0 4px;
    }
  }
  &__select {
    padding: 1px 10px 1px 40px;
    position: relative;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 4px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 9px;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 9.9px;
      height: 0px;
      top: 50%;
      border: 1px solid #bfbfbf;
    }
    &::before {
      left: 15px;
      transform: rotate(-45deg) translateY(-50%);
    }
    &::after {
      left: 7px;
      transform: rotate(45deg) translateY(-50%);
    }
  }

  &__modal {
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;

      input,
      textarea {
        width: 100%;
        margin-left: 0;
      }
    }

    button {
      margin: 15px auto 0;
    }
  }

  &__date {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    width: calc(100% - 45px);

    svg {
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 101;
    }

    > .DatePicker {
      margin: 6px 12px;
      width: 100%;
      text-align: right;
      margin-bottom: 0;

      input {
        margin: 0;
        width: 100%;
      }
    }
  }

  &__selectBox {
    margin-top: 6px;
    > div {
      width: 100%;

      > div {
        padding-right: 22px;
      }
    }
  }
}

.validation {
  &__description,
  &__message {
    font-size: 12px;
    color: #bd272d;
  }
  &__message {
    margin-right: 50px;
  }
}
