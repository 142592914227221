body {
  // font-family: 'IRANSans';
  .wrapper {
    min-height: 100vh;
    &--empty {
      min-height: 100vh;
    }
  }

  .dashboard-layout {
    margin: auto;
    height: 100vh;
    direction: rtl;
    max-width: 500px;
    overflow: scroll;
    margin: auto;
    background: #e5e5e5;
    > .content {
      padding: 15px;
    }
  }
}

@media (max-height: 750px) {
  .DatePicker {
    // z-index: 1000;
    &__calendarContainer {
      position: absolute;
      top: -150px !important;
      left: 50%;
      transform: translateX(-50%);
      z-index: 200000;
    }
  }
}
