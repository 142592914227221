.residence {
  font-family: IRANYekan;
  max-width: 500px;
  margin: auto;

  &__box {
    margin: 16px;
    height: fit-content;
    padding-bottom: 10px;
    background-color: white;
    border-radius: 6px 6px 6px 6px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  }

  &__header {
    position: relative;

    > img {
      width: 100%;
      height: 120px;
      opacity: 0.7;
      filter: brightness(0.4);
      border-radius: 6px 6px 0px 0px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    }

    > span {
      position: absolute;
      right: 22px;
      bottom: -10px;

      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 100px;
        box-sizing: border-box;
        border: 1px solid #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
        /* border-radius: 50%; */
      }
    }

    &-title {
      position: absolute;
      bottom: 15px;
      color: white;
      /* right: 0; */
      display: grid;
      right: 115px;

      span:first-child {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
        text-align: right;
      }

      span:last-child {
        font-weight: bold;
        font-size: 15px;
        line-height: 26px;
      }
    }
  }

  &__price {
    text-align: center;
    margin-top: 20px;

    span:first-child {
      font-weight: bold;
      font-size: 15px;
      color: #999999;
      padding-left: 10px;
    }
  }

  &__statistics {
    display: flex;
    margin: 22px 0;
    direction: rtl;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;

    &--disable {
      background-color: gainsboro;
    }

    > div {
      display: flex;
      min-width: 110px;
      min-height: 100px;
      align-items: center;
      flex-direction: column;

      span {
        font-size: 13px;
        color: #999999;
        text-align: center;
      }

      div {
        width: 60px;
        height: 60px;
        display: flex;
        border-radius: 50%;
        align-items: center;
        box-sizing: border-box;
        justify-content: center;
        border: 1px solid #d9d9d9;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);

        svg {
          height: 30px;
          color: #ffa70b;
          width: 30px !important;
        }
      }
    }
  }

  hr {
    border-top: unset;
    border-bottom: 1px solid #d9d9d9;
  }

  &__payment {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 20px;

    button {
      margin: 0 5px;
      p {
        font-size: 13px;
        font-weight: normal;
      }
    }
  }

  &__costDetails {
    direction: rtl;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 15px 20px;

    > div {
      display: flex;
      align-items: center;
      margin: 5px 0 5px 10px;

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        color: #9b9b9b;
      }

      > div {
        width: 11px;
        height: 11px;
        margin-left: 5px;
      }
    }
  }

  &__costs {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    > span {
      color: #9b9b9b;
      margin-bottom: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
    }

    &-box {
      height: 35px;
      display: flex;

      div:first-child {
        border-radius: 5px 0px 0px 5px;
      }

      div:last-child {
        border-radius: 0px 5px 5px 0px;
      }

      > div {
        height: 100%;
        color: white;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
