@import '../../../assets/styles/base-hub.scss';
.add-building {
    margin: auto;
    direction: rtl;
    max-width: 500px;
    min-height: 100vh;
    padding-bottom: 100px;
    background: #d9d9d9;
    font-family: iranyekan;
    .stepper-container-horizontal {
        direction: rtl;
        margin: 0px auto;
        border-radius: 3px;
        padding: 30px 0px 17px;
    }
    .buttons-container {
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 70px;
        display: flex;
        position: fixed;
        max-width: 500px;
        text-align: center;
        align-items: center;
        border-top: 1px solid white;
        justify-content: space-around;
        background-color: #d9d9d9d9;
        button {
            p {
                font-size: 13px;
            }
        }
    }
    &__container {
        margin: 0 15px;
    }
    &__body {
        border-radius: 6px;
        padding: 15px 15px 50px;
        background-color: white;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
        label {
            font-size: 14px;
            font-weight: normal;
        }
        [type='text'] {
            width: 70%;
            font-size: 13px;
            margin: 6px 0;
        }
        >div {
            >div {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
    &__map {
        width: 100%;
        height: 110px;
        background: #ececec;
        margin: 20px 0;
    }
    &__upload {
        display: flex;
        >div {
            position: relative;
            background: #fafafa;
            border: 1px dashed #d9d9d9;
            box-sizing: border-box;
            border-radius: 2px;
            height: 130px;
            width: 128px;
            max-width: 100%;
            display: flex;
            align-items: center !important;
            justify-content: center !important;
            flex-direction: column;
            margin-right: calc(50% - 90px);
            span {
                color: rgba(0, 0, 0, 0.65);
            }
            span:first-child {
                font-size: 20px;
            }
            >input {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                opacity: 0;
                width: 100%;
                margin: 0;
                border: none;
                padding: 0;
                height: 100%;
            }
        }
    }
}

.calculating {
    font-family: iranyekan;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    height: fit-content;
    padding: 2rem 1rem;
    &__blocks {
        display: flex;
        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 25px 10px;
        justify-content: space-between;
        position: relative;
        margin: 30px 0;
        >span {
            position: absolute;
            top: -11px;
            background-color: white;
            padding: 0 10px;
        }
    }
    &__period {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        span {
            font-weight: normal;
            font-size: 16px;
            line-height: 38px;
            color: #a2a2a2;
            padding-right: 8px;
        }
    }
}

.calculating__type {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 34px;
    .light {
        width: fit-content;
        label {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;
            width: inherit;
            margin: 0px 0 8px;
            position: relative;
        }
        input {
            opacity: 0;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            width: fit-content;
        }
        /* .design */
        .design {
            height: 16px;
            border: 1px solid #ffa70b;
            border-radius: 100%;
            margin-right: 16px;
            position: relative;
            width: 16px;
            margin-left: 8px;
        }
        .design::before,
        .design::after {
            content: '';
            display: block;
            width: inherit;
            height: inherit;
            border-radius: inherit;
            position: absolute;
            right: -1px;
            bottom: -1px;
            transform: scale(0);
            transform-origin: center center;
        }
        .design:before {
            background: $secondary_color;
            opacity: 0;
            transition: 0.3s;
        }
        .design::after {
            background: $secondary_color;
            opacity: 0.4;
            transition: 0.6s;
        }
        /* .text */
        .text {
            color: #999999;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
        }
        /* checked state */
        input:checked+.design::before {
            opacity: 1;
            transform: scale(0.6);
        }
        /* other states */
        input:hover+.design,
        input:focus+.design {
            border: 1px solid $secondary_color;
        }
        input:hover~.text {
            color: #999999;
        }
        input:focus+.design::after,
        input:active+.design::after {
            opacity: 0.1;
            transform: scale(1.2);
        }
    }
}

.facilities {
    display: flex;
    border-radius: 6px;
    padding: 1rem 1rem;
    height: fit-content;
    align-items: center;
    margin-bottom: 100px;
    background: #ffffff;
    flex-direction: column;
    font-family: iranyekan;
    height: -moz-fit-content;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    span:first-child {
        margin-bottom: 3px;
    }
    &__container {
        margin: 4px;
        padding: 10px;
        display: flex;
        font-size: 14px;
        color: #999999;
        line-height: 22px;
        text-align: right;
        border-radius: 4px;
        font-weight: normal;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        border: 1px solid #d9d9d9;
        min-width: calc(44vw - 20px);
    }
    .checkboxes {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }
    input {
        width: 20px;
        height: unset;
        outline: none;
        margin: 0 12px;
        appearance: none;
        position: relative;
        height: 20px !important;
        border: 1px solid #d9d9d9;
        @include easeTransition(0.3s);
        &:after {
            top: 0;
            width: 100%;
            content: '✓';
            right: -1px;
            height: 100%;
            color: white;
            font-size: 16px;
            text-align: center;
            position: absolute;
        }
    }
    .checkmark {
        top: 0;
        left: 0;
        width: 25px;
        height: 25px;
        position: absolute;
        background-color: #eee;
    }
    input:checked {
        background-color: $secondary_color;
    }
}

.validation {
    &__description,
    &__message {
        font-size: 12px;
        color: #bd272d;
    }
    &__message {
        margin-right: 50px;
    }
}