@import '../../assets/styles/base-hub.scss';

.toast {
  left: 16px;
  right: 16px;
  direction: rtl;
  z-index: 10001;
  position: fixed;
  font-weight: 300;
  border-radius: 6px;
  font-size: 0.77rem;
  padding: 0 0.88rem;
  @include easeTransition(0.3s);
  @include makeCenter(flex-start, center, column);

  &__enabled {
    opacity: 1;
    bottom: 5rem;
    padding: 10px;
    color: gray;
    text-align: center;
    background: $white;
    @include makeCenter(flex-start, flex-start, row);
    box-shadow: 0 2px 12px 0 rgba($black, 0.1);

    &--warn {
      color: #ffa70b;
    }

    &--danger {
      color: red;
    }
    &--success {
      color: #26de81;
    }
  }

  svg {
    font-size: 16px;
    margin-right: 10px;
    margin-top: 3px;
  }

  span {
    font-size: 13px;
    font-weight: 500;
    text-align: start;
    line-height: 22px;
    font-style: normal;
    padding-right: 10px;
    font-family: IRANYekan;
    color: rgba(0, 0, 0, 0.65);
  }

  &__disabled {
    opacity: 0;
    color: blue;
    bottom: -3.5rem;
    text-align: center;
    background: $white;
    @include easeTransition(0.2s);
    @include makeCenter(center, flex-start, row);
    box-shadow: 0 2px 12px 0 rgba($black, 0.1);
  }
}
