.dashboard {
  background-color: #d9d9d9;
  padding-bottom: 30px;
  min-height: 100vh;
  max-height: fit-content;
  direction: rtl;
  > button {
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0.9;
    border: 1px dashed #39afca;
    box-sizing: border-box;
    border-radius: 6px;
    width: 91%;
    height: 70px;
    margin-left: 20px;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;

    span {
      font-size: 13px;
      line-height: 30px;
      text-align: center;
      color: #39afca;
      font-weight: 500;
      font-family: IRANYekan;
    }
  }
}
