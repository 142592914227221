@import './../../../assets/styles/base-hub.scss';

body {
  font-family: iranyekan;
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #000;
  outline: 1px solid #000;
}

body {
  font-size: 14px;
  line-height: 1.7;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

a,
a:link {
  text-decoration: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

a {
  color: #000;
}

.btn {
  display: inline-flex;
  padding: 10px 30px;
  font-size: 1.2em;
  @include border-radius(4px);
  color: #fff;
  border-color: $primary_color;
  background-color: $primary_color;
  @include transition(all, 0.3s, ease);
  &:hover {
    color: #fff;
    background-color: darken($color: $primary_color, $amount: 10);
  }
}

h2.hTitle {
  text-align: center;
  font-weight: bold;
}

h3.hTitle {
  font-size: 15px;
  font-weight: bold;
  position: relative;
  margin: 0;
  padding: 6px 0;
  margin-bottom: 6px;
  &:after {
    content: '';
    display: block;
    width: 40px;
    height: 2px;
    margin-top: 5px;
    background: $primary_color;
  }
}

.titleRow {
  margin-bottom: 30px;
  h2 {
    font-size: 2em;
    margin: 0;
    color: $primary_color;
  }
  h4 {
    font-weight: 300;
    color: $gray_color;
    font-size: 1.3em;
  }
}

.center {
  text-align: center;
  justify-content: center;
}

/*------------------ Home ------------------*/

.landing-right {
  .hero-section {
    background-image: url(../../../assets/img/slider-bg-1.jpg);
  }

  .hero-section {
    img {
      @media (max-width: 768px) {
        height: 300px;
        padding-bottom: 20px;
      }
    }

    .content {
      @media (max-width: 768px) {
        max-width: unset;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;

        h3 {
          padding: unset;
        }
      }
    }
  }

  #header {
    direction: rtl;
  }

  .content {
    max-width: 33.33333%;
    direction: rtl;

    h3 {
      padding-left: 45px;
    }
  }

  .section_spec,
  .services_row {
    direction: rtl;
  }
}

.landing-left {
  .hero-section {
    background-image: url(../../../assets/img/slider-bg-2.jpg);
    background-position: top right;
    .row {
      flex-direction: row-reverse;
    }
  }
  #footer {
    .contact_row {
      flex-direction: row-reverse;

      > div:last-child {
        flex-direction: row-reverse;

        span:last-child {
          direction: ltr;
          font-family: monospace;
          margin: 0 5px;
        }
      }
    }
  }

  .hero-section {
    img {
      @media (max-width: 768px) {
        height: 300px;
        padding-bottom: 20px;
      }
    }

    .content {
      @media (max-width: 768px) {
        max-width: unset;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;

        h3 {
          padding: unset;
        }
      }
    }
  }
  #header {
    direction: ltr;
  }

  .content {
    max-width: 33.33333%;
    direction: ltr;

    h3 {
      padding-right: 45px;
    }
  }
  #section4 {
    .post__body {
      direction: ltr;
    }
  }
}

.hero-section {
  height: 100vh;
  min-height: 900px;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  display: flex;
  align-items: center;

  @include breakpoint(lg) {
    min-height: 700px;
    height: auto;
  }
  @include breakpoint(md) {
    align-items: flex-end;
  }

  .container {
    margin-top: 5rem;

    .row {
      align-items: center;

      h3 {
        font-weight: 300;
      }

      @include breakpoint(md) {
        flex-direction: column;
        text-align: center;
      }

      .img_col {
        padding-bottom: 20px;
        img {
          @include breakpoint(md) {
            height: 300px;
          }
        }
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border-style: none;
  }
  .row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-left: -20px;
    justify-content: space-between;
  }

  h3 {
    font-weight: 300;
  }

  .image {
    padding-bottom: 20px;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
}

.services_row {
  padding: 100px 0;
}

.container {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 992px) and(max-width: 1200) {
    max-width: 960px;
  }
  @media (min-width: 768px) and(max-width: 992) {
    max-width: 720px;
  }
  @media (min-width: 576px) and(max-width: 768) {
    max-width: 540px;
  }
}

.cta-box {
  background-color: $primary_color;
  text-align: center;
  padding: 3em 20px;
  color: #fff;
  p {
    font-size: 1.3em;
    font-weight: 300;
  }
}

.parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section_01 {
  height: 400px;
  background-image: url(../../../assets/img/cover_01.jpg);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  flex-direction: column;

  .title {
    font-size: 2rem;
  }
  .txt {
    font-size: 1.2rem;
  }
  .title,
  .txt {
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    @include border-radius(8px);
    text-align: center;
    margin: 5px 20px;
  }
}

.section_spec {
  text-align: center;
  padding: 100px 0;
}

#header {
  position: fixed;
  width: 100%;
  z-index: 999;
  padding: 15px 0;
  @include transition(all, 0.6s, ease);
  background: rgba(255, 255, 255, 0.95);
  .header-logo {
    min-width: 200px;
    img {
      display: block;
      max-height: 100px;
      @include transition(all, 0.6s, ease);

      @include breakpoint(sm) {
        max-height: 50px;
      }
    }

    @include breakpoint(md) {
      min-width: auto;
    }
  }
  &.smaller {
    min-height: 60px !important;
    background: rgba(255, 255, 255, 0.95);
    @include box-shadow(0, 0, 15px, rgba(0, 0, 0, 0.2));
    padding: 10px 0;
    .header-logo {
      img {
        max-height: 50px;
      }
    }
  }
}

.header-body {
  width: 100%;
  @include breakpoint(xs) {
    padding: 0 10px;
  }
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: space-around;
  }
}

.headerMenu {
  ul.nav {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1.2em;
    li {
      padding: 0 10px;

      @include breakpoint(sm) {
        width: 100%;
      }
    }
    @include breakpoint(lg) {
      flex-direction: column;
      margin-top: 20px;
    }
  }
  a {
    font-size: 1.1em;
    @include breakpoint(lg) {
      padding: 12px 15px;
      color: #fff;
      display: block;
      position: relative;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    &:after {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      @include transition(all, 0.2s, ease);
      @include breakpoint(lg) {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
    &:hover,
    &.active {
      color: $primary_color;
      &:after {
        background-color: $secondary_color;
      }
    }
  }
  @include breakpoint(lg) {
    height: 100%;
    width: 0;
    min-width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    background: #333;
    color: #fff;
    @include box-shadow(1px, 1px, 20px, rgba(0, 0, 0, 0.4));
    .openMobSide {
      background: none;
      color: #fff;
      margin: 10px 10px 0 0;
    }
  }
}

body.open_menu .headerMenu {
  width: 220px;
}

.all_body {
  @include transition(all, 1s, ease);
}

body.open_menu {
  .all_body {
    filter: blur(5px);
  }
}

.openMobSide {
  display: none;
  @include breakpoint(lg) {
    display: block;
    width: 40px;
    height: 40px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    @include box-shadow(1px, 1px, 5px, rgba(0, 0, 0, 0.15));
    i {
      font-size: 1.5em;
    }
  }
}

.cta_header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/*------------------ services ------------------*/
.services_row {
  padding: 100px 0;
  @include breakpoint(xs) {
    padding-right: 40px;
    padding-left: 40px;
    overflow: hidden;
  }
}

.feature-box {
  background: #fff;
  padding: 10px 30px;
  text-align: center;
  height: 100%;
  @include border-radius(6px);
  @include box-shadow(0, 0, 20px, rgba(0, 0, 0, 0.1));
  @include breakpoint(md) {
    margin-bottom: 40px;
    height: auto;
  }
  .feature-box-icon {
    padding: 0;
  }
  .feature-box-info {
    .title {
      font-size: 1.2em;
    }
    .txt {
      text-align: justify;
      line-height: 1.7;
      color: $gray_color;
    }
  }
}

.featured-item {
  padding: 20px;
  text-align: center;
  margin: 15px 0;

  .ico {
    font-size: 3em;
    color: $secondary_color;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background-color: #fff;
    @include border-radius(100%);
    border: 3px solid lighten($color: $gray_color, $amount: 50);
    @include transition(all, 0.3s, ease);
  }
  h4 {
    font-size: 1.4em;
    margin-top: 1em;
  }
  &:hover {
    .ico {
      background-color: $primary_color;
      color: #fff;
      border: 3px solid $primary_color;
    }
  }
}

.section_blog {
  padding: 80px 0;
  background-color: #eee;
}

.post {
  @include border-radius(8px);
  overflow: hidden;
  @include transition(all, 0.6s, ease);
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  direction: rtl;
  font-family: iranyekan;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
  @include breakpoint(lg) {
    margin-bottom: 20px;
    height: auto;
  }

  &:hover {
    @include box-shadow(0, 0, 20px, rgba(0, 0, 0, 0.2));
    img {
      transform: scale(1.1);
    }
  }

  &__img {
    margin: 0;
    overflow: hidden;
    img {
      @include transition(all, 0.2s, ease);
      height: 260px;
      width: 100%;
      object-fit: cover;
    }
  }

  &__body {
    flex: 2;
    padding: 20px;
    text-align: justify;
    @include border-radius(8px);
    position: relative;
    display: flex;
    flex-direction: column;
    h3 {
      flex: 2;
    }
    a.btn {
      align-self: flex-start;
    }
  }
}

.spec {
  display: flex;
  flex-wrap: wrap;
  &__item {
    padding: 10px;
    @include border-radius(10px);
    border: 1px solid rgba(0, 0, 0, 0.1);
    @include transition(all, 0.6s, ease);
    margin: $grid-gutter-width / 4;
    width: calc(20% - #{$grid-gutter-width / 2});

    @include breakpoint(lg) {
      width: calc(25% - #{$grid-gutter-width / 2});
    }
    @include breakpoint(md) {
      width: calc(33% - #{$grid-gutter-width / 2});
    }
    @include breakpoint(sm) {
      width: calc(50% - #{$grid-gutter-width / 2});
    }

    img {
      display: block;
      max-width: 100%;
    }
    h4 {
      margin: 0;
      padding-bottom: 10px;
      font-weight: bold;
    }
    &:hover {
      @include box-shadow(0, 0, 15px, rgba(0, 0, 0, 0.2));
    }
  }
}

/*------------------ footer ------------------*/

#footer {
  background-color: #252525;
  direction: rtl;
  background-size: cover;
  color: #fff;
  padding: 30px 0;
  a {
    color: #fff;
    &:hover {
      color: $primary_color;
    }
  }
  .footr_logo {
    padding: 70px 20px;
    text-align: center;
    @include breakpoint(sm) {
      padding: 20px 20px;
    }
  }
  .contact_row {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    @include breakpoint(sm) {
      text-align: center;
      flex-direction: column !important;
      font-weight: 100;
    }
  }
  .copyright_row {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    font-weight: 100;
    @include breakpoint(sm) {
      flex-direction: column-reverse;
      text-align: center;
      align-items: center;
    }
    p {
      margin: 0;
    }
    .sub-menu {
      ul {
        display: flex;
        li {
          padding: 0 6px;
          border-left: 1px solid rgba(255, 255, 255, 0.2);
          &:first-child {
            border-right: 1px solid rgba(255, 255, 255, 0.2);
          }
        }
      }
      @include breakpoint(sm) {
        padding: 15px 0;
      }
    }
  }
  .social-icons {
    display: flex;
    list-style: none;
    padding: 0;
    li {
      padding: 0 8px;
      a {
        display: flex;
      }
      &:last-child {
        padding-left: 0;
      }
    }
    svg {
      font-size: 1.5em;
    }
  }
}
