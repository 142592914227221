@import '../../../assets/styles/base-hub.scss';

.edit-profile {
  margin: auto;
  height: 100vh;
  overflow: scroll;
  direction: rtl;
  max-width: 500px;
  background: #d9d9d9;

  &__container {
    margin: 110px 16px 0 15px;
  }

  &__seperator {
    border-top: 1px solid #d9d9d9;
    margin: 15px 0 0;
  }

  &__profile-card {
    height: 391px;
    direction: rtl;
    display: flex;
    border-radius: 6px;
    margin: 22px 0 14px 0;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  }

  &__header {
    &-title {
      font-size: 15px;
      line-height: 26px;
      font-weight: bold;
      font-style: normal;
      font-family: IRANYekan;
    }

    width: 100%;
    height: 52px;
    display: flex;
    padding: 0 14px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    height: 337px;
    display: flex;
    flex-direction: column;
  }

  &__section {
    display: flex;
    margin-right: 0;
    margin: 15px 15px 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &--profile {
      align-items: flex-start;
    }

    span {
      display: flex;
      font-size: 14px;
      line-height: 22px;
      font-style: normal;
      font-weight: normal;
    }

    label {
      font-size: 14px;
      min-width: 92px;
      margin-left: 5px;
      line-height: 22px;
      margin-right: 2px;
      font-style: normal;
      font-weight: normal;
      font-family: IRANYekan;
    }

    &-pricture {
      height: 100px;
      display: flex;
      flex-direction: row;
    }

    &-image {
      width: 100px;
      display: flex;
      font-size: 14px;
      margin-left: 10px;
      border-radius: 2px;
      align-items: center;
      background: #fafafa;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: center;
      border: 1px dashed #d9d9d9;
      color: rgba(0, 0, 0, 0.45);

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
    }

    input {
      margin: 0;
      font-size: 13px;
      min-width: 215px;
      color: rgba(0, 0, 0, 0.65);
    }

    button {
      margin: 50px auto 0;
    }
  }

  &__exit-btn {
    margin-top: 14px;
  }

  &__bio {
    display: flex;
    height: 140px;
    position: relative;
    align-items: center;
    justify-content: center;
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(50, 50, 113, 1) 35%, rgba(0, 212, 255, 1) 100%);

    > div {
      top: 80px;
      display: flex;
      position: absolute;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 50%;
      margin-bottom: 5px;
      border: 1px solid white;
    }

    h4 {
      color: rgba(0, 0, 0, 0.65);
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      margin: 0;
      text-align: center;
    }

    span {
      color: rgba(0, 0, 0, 0.65);
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 30px;
    }
  }
}
