.registration-code {
  max-width: 500px;
  margin: auto;
  direction: rtl;
  height: 100vh;
  background: #d9d9d9;
  &__header {
    padding: 15px;
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    direction: rtl;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);

    > img {
      height: 50px;
    }

    span {
      height: 40px;

      img {
        width: 40px;
        border-radius: 50%;
        box-sizing: border-box;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
        border-radius: 20px;
        height: 40px;
      }
    }
  }

  &__main {
    background: rgba(255, 255, 255, 0.7);
    opacity: 0.9;
    border: 1px dashed #d9d9d9;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    margin: 40px 16px;
    justify-content: center;
    padding: 30px 0;

    span {
      font-weight: normal;
      font-size: 13px;
      line-height: 30px;
      margin-bottom: 20px;
      color: #999999;
      width: 80%;
    }

    label {
      display: block;
    }

    button {
      p {
        font-size: 14px;
      }
    }
  }
}
