@import '../../../assets/styles/base-hub.scss';

.otp {
  background-image: url('../../../assets//img/bg2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  padding: 0 30px;
  @include makeCenter(center, space-between, column);

  &__footer {
    padding: 0 30px;
    width: 100%;
    @include makeCenter(center, center, column);
  }

  button:first-child {
    margin-bottom: 15px;
  }

  label {
    display: none;
  }

  input {
    width: 100%;
    margin: 0;
    margin: 14px 0;
    text-align: center;
  }

  &-box {
    height: fit-content;
    margin: auto;
    width: 100%;
    max-width: 500px;
    @include makeCenter(center, center, column);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 0px 0px 6px 6px;
    padding-bottom: 20px;

    h3 {
      font-family: IRANYekan;
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 22px;
      margin-bottom: 30px;
    }
    > h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      padding: 20px 0;

      //   > a {
      //     font-weight: bold;
      //     font-size: 14px;
      //     line-height: 30px;
      //     color: $primary_color;
      //     padding-right: 5px;
      //   }
    }
  }

  &__change {
    text-align: center;
    direction: rtl;

    span {
      padding-left: 10px;
    }

    a {
      font-family: IRANYekan;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 22px;
      color: #39afca;
    }
  }
  &__main {
    h2 {
      margin-top: 20px;
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      line-height: 35px;
    }
    button {
      p {
        font-weight: normal;
        font-size: 13px;
      }
    }
  }

  &__timer {
    text-align: center;
    color: gray;
    font-size: 15px;
    padding: 10px 0;
  }

  &__header {
    height: 150px;
    background: $secondary_color;
    width: 100%;
    text-align: center;
    border-radius: 6px 6px 0px 0px;

    img {
      height: 100%;
      width: 50%;
    }
  }

  &__divider {
    border: 1px solid #bdbdbd;
    position: relative;
    width: 100%;
    height: 0;
    margin-top: 32px;

    span {
      position: absolute;
      width: 26px;
      border: 1px solid $white;
      text-align: center;
      border-radius: 50%;
      height: 26px;
      background-color: $white;
      color: #828282;
      left: calc(50% - 13px);
      top: -13px;
    }
  }

  &__social {
    width: 100%;
    margin: 22px 0 0;
    @include makeCenter(center, space-between, row);
    img {
      height: 30px;
    }
  }
}

.login-otp {
  position: relative;

  &__wrapper {
    right: 0;
    display: flex;
    position: fixed;
    white-space: nowrap;
    transition: all 0.2s linear;
  }

  &__container {
    display: flex;
    flex-direction: column;

    .multiInput {
      display: flex;
      direction: ltr;
      margin: 1rem auto 0;

      input {
        flex: 1 1;
        padding: 0;
        margin: 1px;
        border: none;
        min-width: 0;
        max-width: 35px;
        color: gray;
        border-radius: 0;
        text-align: center;
        font-size: 1rem;
        // border: 1px solid gray;

        &:focus {
          outline: none !important;
          border: 1px solid $primary_color;
        }
      }

      &.has-error {
        input {
          border-bottom-color: red;
        }
      }
    }
  }

  &__message {
    text-align: center;
    margin: 1.5rem auto 0;
    @include makeCenter(center, center, row);

    &-gray {
      color: #a3a3a3;
      font-size: 0.7rem;
    }

    &-green {
      color: green;
      font-size: 0.9rem;
      margin: 0 1rem;
      font-weight: 500;
    }
  }

  .login-otp__error-alert {
    border: 0;

    .alert-card__title > span {
      font-size: 0.7rem;
      color: red;
    }
  }
}
